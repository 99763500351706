/* Login v2 styles */
.login-container {
  background-color: #fff;
  height: 100vh;
  overflow: hidden;
}

.login-container .height-100per {
  height: 100% !important;
}
@media (max-width: 480px) {
  .d-xs-none {
    display: none;
  }
}

@media (max-width: 768px) {
  .d-sm-done {
    display: none;
  }
}

.login-container .login-v2-form {
  background-image: url("../assets/images/loginv2/overlay-left.png");
  /* background-image: url("C:/xampp/htdocs/thedal-assets/screen6/06 (19).png"); */
}

.login-container .login-v2-image {
  /* background-image: url("../assets/images/blupage-information.png"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-origin: border-box;
  background-position-y: center;
  /* background-color: #099bff !important; */
  /* background-color: #006cb6 !important; */
  /* position: relative !important; */
}

.login-container .font-23 {
  font-size: 23px !important;
}

.login-container .voter-hands {
  position: absolute;
  left: -25px;
  bottom: -250px;
  display: block;
}

.login-container .headings {
  padding-top: 6rem;
  padding-left: 1rem;
  display: block;
}

.login-container .headings p,
.login-container .headings h2 {
  color: #fff !important;
  font-size: 18px;
}

.login-container .box-shadow-v2 {
  box-shadow: rgb(0 0 0 / 5%) 0px 3px 15px !important;
}

.login-container .headings .one-step {
  font-size: 40px;
}

.login-container .login-content .voter-hands {
  /* background-image: url("./assets/images/loginv2/voters1.png"); */
}

.auth-login-form-v2 input.form-control-v2:not(:focus) {
  border-color: #005d9d !important;
  border: 1px solid #005d9d;
}

.auth-login-form-v2 input.form-control-v2:-webkit-autofill {
  /* -webkit-box-shadow: "#fff" !important; */
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
  -webkit-text-fill-color: #000 !important;
}

.auth-login-form-v2 .form-control-v2 {
  display: block;
  width: 100%;
  padding: 0.571rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  background-color: #fff !important;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  appearance: none;
  border-radius: 0.357rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.auth-login-form-v2 .form-control-v2:focus,
.auth-login-form-v2 .form-control:focus,
.auth-login-form-v2 input.form-check-input:not(:checked):not(:indeterminate) {
  background-color: transparent !important;
  color: #000 !important;
  border-color: #005d9d !important;
}

.auth-login-form-v2 input.form-check-input:checked {
  background-color: #005d9d !important;
  border-color: #005d9d !important;
}

.auth-login-form-v2 button.submit-btn {
  border-color: #005d9d !important;
  background-color: #005d9d !important;
  color: #fff !important;
}

.color-v2 {
  color: #005d9d !important;
}
.v2-font-14 {
  font-size: 14px !important;
}

.v2-fw-500 {
  font-weight: 500 !important;
}
/* Login v2 styles end */
