body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.detail-panel-class {
  background-color: #fff;
}

.light-layout td {
  color: #000000 !important;
}

.dark-layout td {
  color: white !important;
}

.light-layout th {
  color: #000000 !important;
}

.dark-layout th {
  color: #d5d5d5 !important;
}

.dark-layout .menu-item {
  color: white !important;
}

.dark-layout .menu-title {
  color: white !important;
}

.light-layout .active .menu-item {
  color: #fff !important;
}

.light-layout .menu-item {
  color: black !important;
}

.light-layout .menu-title {
  color: black !important;
}

.light-layout .card-text {
  color: black !important;
}

.light-layout .activity-message {
  color: black !important;
}

.dark-layout .card-text {
  color: white !important;
}

.dark-layout .activity-message {
  color: white !important;
}

#bootstrap-table
  div.table-responsive
  table
  input.form-check-input[type="checkbox"]:checked {
  background-size: "11px !important";
}

input.form-radio-input[type="radio"]:checked {
  background-size: "11px !important";
}

#bootstrap-datatable {
  padding-top: 10em;
  margin-left: 260px;
}
/* #react-dataTable.react-dataTable div:first-child {
  background-color: #283046 !important;
  color: #eee !important;
  font-size: 15px !important;
} */

#data-grid-container .selected-items {
  animation: fadeIn 0.1s ease-in both;
}

.election-ac-pc-list {
  width: 400px;
  height: 1100px;
  overflow-x: hidden;
  overflow-y: auto;
}

.app-user-list {
  padding: 0 10px;
}

.app-user-list .row .card {
  border: 1px solid;
  border-radius: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.ml-1 {
  margin-left: 5px;
}

.ml-2 {
  margin-left: 10px;
}

.mr-2 {
  margin-right: 10px;
}

.dark-layout .select__control {
  background-color: #2a3045 !important;
  border-color: #414656 !important;
}

.dark-layout .select__menu {
  background-color: #2a3045 !important;
}

.dark-layout .select__input {
  color: #b4b7bd !important;
}

.dark-layout .select__single-value {
  color: #b4b7bd !important;
}

.is-invalid .select__control {
  border: 1px solid #e15a59 !important;
  border-radius: 5px !important;
}

.light-layout .select__multi-value {
  background: #e9e9e9;
}

.dark-layout .select__multi-value {
  background: #181d31;
}

.dark-layout .select__multi-value__label {
  color: #b0b3ba;
}

table .action-col {
  background-color: #343d55;
}
.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  right: 0;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
